




import _ from 'lodash';
import {Component, Vue} from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import {Action, State} from 'vuex-class';
import Util from '@/utils/Util';
import GAUtil from '@/utils/GAUtil';
import * as Sentry from '@sentry/browser';
import config from '@/config';
import {APIResource} from '@/utils/APIResources';

@Component({
  name: 'AuthCallback',
})
export default class AuthCallback extends Vue {
  @State private app: any;
  @Action('app/setTargetSegment') private setTargetSegment: any;
  @Action('app/setClientId') private setClientId: any;
  @Action('app/setBundleId') private setBundleId: any;
  @Action('app/setPolicyIds') private setPolicyIds: any;
  @Action('app/reset') private reset: any;
  @Action('app/setValue') private setValue: any;

  private created() {
    console.log(`auth callback created`);
    this.setValue({code: 'dirty', value: false});
    this.setValue({code: 'loadingText', value: 'Authenticating...'});
    this.setValue({code: 'isBroker', value: true});
    const targetSegment = sessionStorage.getItem('targetSegment') || '';

    const code = _.get(this.$route, 'query.code', '');
    const state = _.get(this.$route, 'query.state', '');
    if (code && state) {
      console.log('proceed with oidc callback');
      this.reset();
      this.setValue({code: 'isBroker', value: true});
      this.setValue({code: 'originUrl', value: window.location.href});
      this.setTargetSegment({targetSegment});
      AuthService.oidcCallback(code, state)
        .then((result: any) => {
          const token = result.data.data.token;
          const ts = targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
          console.log(`token key: ${'login-token-' + config.environment + '-' + ts}`);
          localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
          sessionStorage.setItem('login-token-' + config.environment + '-' + ts, token);
          APIResource.setUser(true);
          this.$router.push({name: 'landing', params: {targetSegment: this.app.targetSegment}});
        })
        .catch((e: any) => {
          console.error(e);
          window.location.href = `/coverhub-portal/auth/${targetSegment}/error?error=${e.message}`;
        });
    } else {
      console.error('code and/or state is not available');
    }
  }
}
